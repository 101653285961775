import React, { useEffect, useState } from "react";
import sanitizeHtml from "sanitize-html";
import { PrimaryButton } from "@metyis-ds/button";
import { ESidebarDirection, ESidebarModes } from "@metyis-ds/sidebar";
import { IContent } from "../contexts/ContentContext";
import { IJob } from "../components/jobOffering/types";
import { useCheckWindowSize } from "@metyis-ds/hooks";
import BreadCrumbs from "../components/breadcrumbs/BreadCrumbs";
import ContentSection from "../components/contentSection/ContentSection";
import Divider from "../components/divider/Divider";
import FadeinDiv from "../components/fadeinDiv/FadeinDiv";
import Form from "../components/form/Form";
import Page from "../components/page/Page";
import RedirectBanner from "../components/banner/RedirectBanner";
import { Sidebar } from "../components/sidebar/Sidebar";
import { jobsMeta } from "./meta";
import Avatar from "../assets/avatar.svg";
import Close from "../assets/close.svg";
import Location from "../assets/location.svg";
import Suitcase from "../assets/suitcase.svg";
import "./job.css";
import QuoteList from "../components/quote/QuoteList";

const mandatoryPolicies = {
  candidatesPolicy: true
};

export default ({
  pageContext
}: {
  pageContext: { content: IContent; job: IJob };
}) => {
  const isMobile = useCheckWindowSize(1000);
  const { content, job } = pageContext;
  const {
    description,
    domain,
    form,
    isHugoBoss,
    jobLocation,
    seniority,
    summary,
    title
  } = job;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // block main page scroll while sidebar is open
  useEffect(() => {
    document.documentElement.style.overflow = isSidebarOpen
      ? "hidden"
      : "scroll";

    return () => {
      document.documentElement.style.overflow = "scroll";
    };
  }, [isSidebarOpen]);

  const questionIds = form?.formSections
    ?.map(section =>
      section.questions
        .filter(question => question.id && question.type)
        .map(question =>
          question.type === "Checkbox" ? `_${question.id}` : question.id
        )
    )
    ?.flat()
    ?.join("-");

  const jobSections = sanitizeHtml(description)
    .split("<h2><br /></h2>")
    .join("")
    .split("<h2>")
    .slice(1);

  const whoWeAreEl = isHugoBoss ? (
    <div>
      <p style={{ marginBottom: "24px" }}>
        We are Metyis, a forward-thinking, global company that develops and
        delivers solutions around Big Data, Digital Commerce, Marketing and
        Design and provides Advisory services. We have offices in 15 locations
        with a talent pool of 1000+ employees and more than 50 nationalities,
        dedicated to creating long-lasting impact and growth for our business
        partners and clients.
      </p>

      <p style={{ marginBottom: "24px" }}>
        Together with HUGO BOSS, our esteemed business partner, we have embarked
        on a joint venture and created the HUGO BOSS Digital Campus, dedicated
        to increasing the data analytics, eCommerce and technology capabilities
        of the company and boosting digital sales. The HUGO BOSS Digital Campus
        employees will help create a state-of-the-art data architecture
        infrastructure, advanced business analytics, and the development and
        enhancement of HUGO BOSS’ eCommerce platform and services.
      </p>
      <p style={{ marginBottom: "24px" }}>
        This collaborative environment will provide the capabilities required
        for HUGO BOSS to maximise data usage and support its growth trajectory
        towards becoming the leading premium tech-driven fashion platform
        worldwide.
      </p>
    </div>
  ) : (
    <div>
      <p style={{ marginBottom: "24px" }}>
        <b>
          The next step of your career starts here, where you can bring your own
          unique mix of skills and perspectives to a fast-growing team.
        </b>
      </p>
      <p style={{ marginBottom: "24px" }}>
      Metyis is a global and forward-thinking firm operating across a wide range of industries, developing and delivering AI & Data, Digital Commerce, Marketing & Design solutions and Advisory services. At Metyis, our long-term partnership model brings long-lasting impact and growth to our business partners and clients through extensive execution capabilities.
      </p>
      <p style={{ marginBottom: "24px" }}>
      With our team, you can experience a collaborative environment with highly skilled multidisciplinary experts, where everyone has room to build bigger and bolder ideas. Being part of Metyis means you can speak your mind and be creative with your knowledge. Imagine the things you can achieve with a team that encourages you to be the best version of yourself.
      </p>
      <p>We are Metyis. Partners for Impact.</p>
    </div>
  );

  return (
    <Page
      content={content}
      seo={{ description: summary.summary, image: jobsMeta.image, title }}
    >
      <BreadCrumbs
        crumbsArray={[
          {
            name: "Careers",
            redirectUrl: "/careers"
          },
          {
            name: "Available positions",
            redirectUrl: "/jobs"
          },
          { name: title }
        ]}
      />
      <section className="job">
        <FadeinDiv className="job-header">
          <div className="job-title-wrapper">
            <span className="job-title">{title}</span>
            {!isMobile && (
              <PrimaryButton onClick={() => setIsSidebarOpen(true)}>
                Apply now
              </PrimaryButton>
            )}
          </div>
          <div className="job-details">
            <div className="job-details-field">
              <Suitcase
                className="job-details-field-icon"
                stroke="var(--color-orange)"
              />
              <span className="job-domain">{domain?.name}</span>
            </div>
            <div className="job-details-field">
              <Avatar
                className="job-details-field-icon"
                stroke="var(--color-orange)"
              />
              <span className="job-seniority">{seniority}</span>
            </div>
            <div className="job-details-field">
              <Location
                className="job-details-field-icon"
                stroke="var(--color-orange)"
              />
              <span className="job-location">{jobLocation}</span>
            </div>
          </div>
          {isMobile && (
            <PrimaryButton
              className="job-details-button"
              onClick={() => setIsSidebarOpen(true)}
            >
              Apply now
            </PrimaryButton>
          )}
        </FadeinDiv>
        <ContentSection
          backgroundColor="#F7F9FA"
          description1={{
            description1: whoWeAreEl
          }}
          textSize="sm"
          title="Who we are"
        />
        {jobSections.length > 1 ? (
          jobSections.map((section, idx) => {
            const sectionParts = section.split("</h2>");

            return sectionParts?.[0].toLowerCase().includes("who we are") ? (
              <></>
            ) : (
              sectionParts.length === 2 && (
                <>
                  <ContentSection
                    backgroundColor="var(--color-white)"
                    description1={{
                      description1: (
                        <div
                          className="job-description-section-text"
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                              sectionParts[1].split("<p><br /></p>").join("")
                            )
                          }}
                        />
                      )
                    }}
                    textSize="sm"
                    title={sectionParts?.[0]}
                  />
                  {idx + 1 < jobSections.length && (
                    <div className="job-description-divider">
                      <Divider color="#BBBBBB" />
                    </div>
                  )}
                </>
              )
            );
          })
        ) : (
          <FadeinDiv className="rich-text job-description" threshold={0.1}>
            <div
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(description) }}
            />
          </FadeinDiv>
        )}
        {domain && domain.quoteList ? (
          <QuoteList
            {...(isHugoBoss ? domain.quoteListHugoBoss : domain.quoteList)}
          />
        ) : (
          <></>
        )}
        <RedirectBanner
          backgroundColor="var(--color-blue-dark)"
          textColor="var(--color-white)"
          title="Apply to this position"
          onClick={() => setIsSidebarOpen(true)}
        />
        <Sidebar
          className="sidebar"
          direction={
            isMobile ? ESidebarDirection.BOTTOM : ESidebarDirection.RIGHT
          }
          mode={ESidebarModes.OVER}
          open={isSidebarOpen}
          onClose={() => {
            setIsSidebarOpen(false);
          }}
        >
          <div className="sidebar-header">
            <h3>{title}</h3>
            <Close
              className="sidebar-header-icon"
              onClick={() => {
                setIsSidebarOpen(false);
              }}
            />
          </div>
          <div className="sidebar-body">
            {form.formSections && (
              <div id="form">
                <Form
                  {...form}
                  backgroundColor="#fff"
                  mandatoryPolicies={mandatoryPolicies}
                  successPageText="Your job application was submitted successfully."
                  hiddenValue={questionIds}
                  successPageTitle={title}
                />
              </div>
            )}
          </div>
        </Sidebar>
      </section>
    </Page>
  );
};
